import Head from "next/head";
import Link from "next/link";
import Image from "next/image";
import { Fragment, useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";

import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, LogoutIcon, MenuIcon, XIcon } from '@heroicons/react/solid'

import type { Meta, SiteMenuItem, SiteAppearance, WithChildren, SiteInfo } from "@/types";

import { useRouter } from "next/router";

import Spinner from "../Spinner";
import BlurImage from "../BlurImage";
import PageLoader from "../PageLoader";
import { getBorderRadius } from "@/lib/util";
import { ChevronLeft, Share, X, Menu } from "react-feather";
import { NextSeo } from "next-seo";
import SharePage from "../modals/SharePage";
import { useModal } from "@/lib/hooks/useModal";
import clsx from "clsx";

interface LayoutProps extends WithChildren {
  site?: SiteInfo;
  menu?: SiteMenuItem[];
  slug: string;
  siteId?: string;
  subdomain?: string;
  appearance?: SiteAppearance;
}

export default function Layout({ site, menu, slug, children, appearance }: LayoutProps) {
  const router = useRouter();
  const [showMenu, setShowMenu] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const onScroll = useCallback(() => {
    setScrolled(window.pageYOffset > 100);
  }, []);

  const { openModal } = useModal();

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const goToPage = (value: any) => {
    if (value.slug !== slug) {
      router.push(`/${value.slug}`);
    }
    setShowMenu(false)
  }
  const handleSharing = async () => {
    if (!site) return;

    if (navigator.share) {
      try {
        await navigator
          .share({
            url: `https://${site?.domain}`,
            title: site?.description,
            text: site?.name
          })
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
        openModal(<SharePage site={site} />, "Share")
      }
    } else if (site) {
      openModal(<SharePage site={site} />, "Share")
    }
  };

  return (
    <div
      style={{
        backgroundPosition: '50% 15%',
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        overflow: 'hidden auto',
        maxHeight: '100%'
      }}>
      <NextSeo
        title={site?.name}
        canonical={site?.domain}
        description={site?.description}
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: `https://${site?.domain}`,
          site_name: site?.name,
          images: [
            { url: site?.domain === 'curious.page' ? 'https://curious.page/og-image.png' : `https://s.vercel.app/api?url=https://${site?.domain}/opengraph&width=1200&height=630` },
          ]
        }}
      />

      {/* <PageLoader /> */}

      {(scrolled || slug !== "home") && <div
        className={`fixed mx-auto max-w-3xl w-full ${scrolled || slug !== "home" ? "drop-shadow-mdx border-b-2 border-gray-100" : ""
          }  top-0 left-0 right-0 h-auto py-3 z-30 bg-white/30 transition-all ease duration-150 flex backdrop-blur-xl`}
      >
        <div className="flex items-center justify-center w-full h-full px-4 mx-auto select-none px-10x sm:px-12x">
          {menu && <div className="flex justify-between w-full">
            <Link href="/">
              <div className="flex items-center justify-center cursor-pointer">
                {site?.logo && (
                  <div className="inline-block w-8 h-8 overflow-hidden align-middle rounded-full">
                    <BlurImage
                      alt={site?.name!}
                      height={40}
                      src={site?.logo!}
                      blurDataURL={site?.logoBlurHash}
                      width={40}
                      objectFit="cover"
                    />
                  </div>
                )}
                <span className="inline-block ml-3 font-medium truncate">
                  {site?.name}
                </span>
              </div>
            </Link>
            <div className="flex items-center justify-center space-x-2">
              <div onClick={() => handleSharing()}
                className="flex items-center px-3 py-1.5 space-x-1 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200" aria-label="Go to dashboard">
                <Share className="w-4 h-4 stroke-gray-600" />
                <span className="hidden text-sm text-gray-600 md:block">Share</span>
              </div>
              {menu.length > 1 && <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`
                ${open ? '' : 'text-opacity-90'}
                group flex items-center justify-center py-0 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <div
                        className="flex items-center justify-center px-3 py-1.5 space-x-1 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200" aria-label="Open menu">
                        {!open && <Menu
                          className={clsx(open ? 'fill-gray-500 rotate-180' : 'text-opacity-70',
                            'h-4 w-4 stroke-gray-500 transition duration-300 ease-in-out group-hover:fill-gray-600')}
                          aria-hidden="true"
                        />}
                        {open && <X
                          className={clsx(open ? 'fill-gray-500 rotate-180' : 'text-opacity-70',
                            'h-4 w-4 stroke-gray-500 transition duration-300 ease-in-out group-hover:fill-gray-600')}
                          aria-hidden="true"
                        />}
                        <span className="hidden text-sm text-gray-600 md:block">Pages</span>
                      </div>

                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >

                      <Popover.Panel className="absolute right-0 z-50 max-w-sm mt-2 transform w-80 top-8 sm:px-0">
                        {({ close }) => (
                          <div className="overflow-hidden bg-white border rounded-lg drop-shadow-xl border-opacity-5 ring-1 ring-black ring-opacity-5">
                            {menu && menu.length > 1 && <div className="relative grid gap-8 bg-white py-7 px-7">
                              {menu.map((item) => {
                                const isActive = item.slug === slug;

                                return (<div key={item.slug} onClick={() => {
                                  router.push(`/${encodeURIComponent(item.slug)}`)
                                  close()
                                }}
                                  className={clsx("flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg cursor-pointer", isActive ? "bg-gray-700 hover:bg-gray-700" : "hover:bg-gray-100")}
                                >
                                  <div className="ml-4">
                                    <p className={clsx("text-sm font-medium", isActive ? "text-white" : "text-gray-900")}>
                                      {item.name}
                                    </p>
                                  </div>
                                </div>)
                              })}
                            </div>}
                          </div>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>}
            </div>
          </div>}
        </div>
      </div>
      }

      {!menu &&
        <div className="fixed top-0 left-0 right-0 z-30 flex w-full h-auto max-w-5xl py-1 mx-auto transition-all duration-150 border-b-2 border-gray-100 drop-shadow-mdx bg-white/30 ease backdrop-blur-xl">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <div className="w-20 p-2 cursor-pointer" onClick={() => router.back()}>
                <ChevronLeft className="stroke-gray-500" />
              </div>
              <div className="flex items-center justify-center w-20 font-medium">
                PREVIEW
              </div>
              <div className="w-20"></div>
            </div>
          </div>
        </div>
      }


      <div className="mt-8">{children}</div>


      <style jsx>{`
                .nav-link {
                    color: ${appearance?.buttonTextColor};
                    border-radius: ${getBorderRadius(appearance!)};
                    background-color: ${appearance?.buttonBackgroundColor};
                    border-color: ${appearance?.buttonBackgroundColor};
                }
                .nav-link:hover {
                    color: ${appearance?.buttonBackgroundColor};
                    background-color: transparent;
                }
                .inactive-nav-link {
                    border-radius: ${getBorderRadius(appearance!)};
                    color: ${appearance?.buttonBackgroundColor};
                }
                .inactive-nav-link:hover {
                    border-color: ${appearance?.buttonBackgroundColor};
                }
                .nav-link:hover ~ .arrow-icon {
                    stroke: #111
                }
          `}
      </style>
    </div>
  );
}
